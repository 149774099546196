
































import {Component, Prop, Vue} from 'vue-property-decorator';
import {ETPPaymentStatus, ETPScheduleStatus, ETPScheduleType} from "@/constants/IndividualTaxationConstants";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalInput from "@/components/common/PortalInput.vue";
import {namespace} from "vuex-class";
import ETPService from "@/services/ETPService";
import ETPPaymentDTO from "@/dto/taxation/individual/estimated/ETPPaymentDTO";
import ETPScheduleDTO from "@/dto/taxation/individual/estimated/ETPScheduleDTO";
import PersonService from "@/services/PersonService";
import BankAccountDTO from "@/dto/BankAccountDTO";
import {BankProductType} from "@/dto/company/OtherCompanyBankProductDTO";

const AppModule = namespace("App");

@Component({
  components: {PortalInput, PortalDate},
  computed: {
    BankProductType() {
      return BankProductType
    },
    ETPPaymentStatus() {
      return ETPPaymentStatus
    },
    ETPScheduleStatus() {
      return ETPScheduleStatus
    },
    ETPScheduleType() {
      return ETPScheduleType
    }
  }
})
export default class EstimatedTaxPaymentsSchedule extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  @Prop()
  schedule!: ETPScheduleDTO;

  status = ETPScheduleStatus.PROPOSED;
  type: ETPScheduleType = ETPScheduleType.STATE

  state: string | null = null;

  bankAccounts: Array<BankAccountDTO> = [];

  mounted(){
    this.loadAccounts();
  }

  loadAccounts(){
    this.startLoading();
    PersonService.getBankAccounts(this.schedule.customerId).then(
        ok => {
          this.bankAccounts = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  updatePayment(payment: ETPPaymentDTO){
    this.startLoading();
    ETPService.updatePayment(payment).then(
        ok => {
          this.stopLoading()
        },
        err => {
          this.startLoading();
          console.log(JSON.stringify(err));
        }
    )
  }
;
  chooseAccountMethod(payment: ETPPaymentDTO){
    payment.paymentMethod = BankProductType.ACCOUNT;
  }

  accountSelected(p: ETPPaymentDTO){
    this.startLoading();
    console.log(JSON.stringify(p))
    ETPService.accountPayment(p.id, p.paymentAccount!.id as number).then(
        ok => {
          console.log(ok);
          this.stopLoading();
        },
        err => {
          console.log(err)
          this.stopLoading();
        }
    )
  }

  chooseCardMethod(payment: ETPPaymentDTO){
    // payment.paymentMethod = BankProductType.CARD;
    this.startLoading();
    ETPService.cardPayment(payment.id).then(
        ok => {
          this.stopLoading();
          this.renewPayment(payment)
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  renewPayment(payment: ETPPaymentDTO){
    this.startLoading();
    ETPService.getPayment(payment.id).then(
        ok => {
          payment.status = ok.data.status;
          payment.scheduledDate = ok.data.scheduledDate;
          payment.cardPayment = ok.data.cardPayment;
          payment.paymentMethod = ok.data.paymentMethod;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }


}
