





























































import {Component, Vue} from 'vue-property-decorator';
import ETPSubscriptionDTO from "@/dto/taxation/individual/estimated/ETPSubscriptionDTO";
import {namespace} from "vuex-class";
import ETPService from "@/services/ETPService";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";
import ETPSchedule from "@/components/taxation/individual/estimated/ETPSchedule.vue";
import {ETPScheduleType, ETPSubscriptionStatus} from "@/constants/IndividualTaxationConstants";
import ETPScheduleDTO from "@/dto/taxation/individual/estimated/ETPScheduleDTO";
import UpdateETPSubscriptionPayload from "@/dto/taxation/individual/estimated/UpdateETPSubscriptionPayload";
import {BankProductType} from "@/dto/company/OtherCompanyBankProductDTO";
import BankAccountDTO from "@/dto/BankAccountDTO";
import PersonService from "@/services/PersonService";

const AppModule = namespace("App");

@Component({
  computed: {
    BankProductType() {
      return BankProductType
    },
    ETPSubscriptionStatus() {
      return ETPSubscriptionStatus
    }
  },
  components: {ETPSchedule, PortalCollapse}
})
export default class ETPSubscription extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void

  subscription: ETPSubscriptionDTO | null = null;

  updatePayload = new UpdateETPSubscriptionPayload();

  selectPaymentMethodMode = false;

  bankAccounts: Array<BankAccountDTO> = [];

  mounted() {
    this.loadSubscription();
  }

  loadSubscription() {
    this.startLoading();
    ETPService.getSubscription(this.id).then(
        ok => {
          this.subscription = ok.data;
          this.stopLoading();
        },
        err => {
          console.log(JSON.stringify(err));
          this.stopLoading();
        }
    )
  }

  get id(): number {
    return Number.parseInt(this.$route.params.id);
  }

  buildTitle(schedule: ETPScheduleDTO): string {
    switch (schedule.type) {
      case ETPScheduleType.FEDERAL:
        return `Type: ${schedule.type}, status: ${schedule.status}`;
      case ETPScheduleType.STATE:
        return `Type: ${schedule.type}, state: ${schedule.state}, status: ${schedule.status}`;
    }
  }

  activateSubscription() {
    this.startLoading();
    this.updatePayload.status = ETPSubscriptionStatus.ACTIVE
    ETPService.updateSubscription(this.subscription!.id, this.updatePayload).then(
        ok => {
          this.stopLoading();
          this.loadSubscription();
          this.updatePayload = new UpdateETPSubscriptionPayload();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    );
  }

  changeStatusToPayment() {
    this.startLoading();
    this.updatePayload.status = ETPSubscriptionStatus.PAYMENT_PENDING;
    ETPService.updateSubscription(this.subscription!.id, this.updatePayload).then(
        ok => {
          this.stopLoading();
          this.loadSubscription();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  declineOffer() {
    this.startLoading();
    this.updatePayload.status = ETPSubscriptionStatus.DECLINED;
    ETPService.updateSubscription(this.subscription!.id, this.updatePayload).then(
        ok => {
          this.stopLoading();
          this.updatePayload = new UpdateETPSubscriptionPayload();
          this.loadSubscription();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }

  onPaymentMethodChanged() {
    if(this.updatePayload.paymentMethod == BankProductType.CARD){
      this.changeStatusToPayment()
    }else {
      this.loadAccounts()
    }
  }

  loadAccounts(){
    this.startLoading();
    PersonService.getBankAccounts(this.subscription?.customerId as number).then(
        ok => {
          this.bankAccounts = ok.data;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          console.log(JSON.stringify(err));
        }
    )
  }



}
