export default class OtherCompanyBankProductDTO {

    public id: number | null = null;

    public companyId: number | null = null;

    public accountNumber = "";

    public bankName = "";

    public type: BankProductType | null = null;
}

export enum BankProductType {
    CARD = "CARD",
    ACCOUNT = "ACCOUNT"
}